<template>
  <section class="relative mb-16">
    <h1
      class="
        w-full
        mb-8
        md:w-1/2 md:mb-10
        lg:w-1/2 lg:ml-8
        xl:w-5/12 xl:mb-20 xl:mt-8
      "
    >
      Que hacer ante una situación de accidente, con o sin lesionados
    </h1>
    <p class="cita">
      Luego de sufrir un accidente de tránsito es común que el miedo o los
      nervios nos paralicen y no sepamos qué hacer. Por eso te damos algunos
      consejos.
    </p>
    <div class="md:absolute md:right-0 md:top-0 md:-mr-24 lg:-mr-12 xl:mr-16">
      <img src="../assets/img/ommm.svg" alt="Ommm" />
    </div>
  </section>

  <section>
    <div class="consejos">
      <ul class="consejos-list">
        <li>Trata de conservar la calma</li>
        <li>
          Llama al 911 (Emergencias). Allí podrás denunciar el accidente a la
          Policía, y si hubo lesionados, al SEC (Servicio de Emergencias
          Coordinado) (Art. 108 inc. 2 de la Ley de Tránsito 9024). No movilizar
          a los heridos, salvo que se tenga preparación en primeros auxilios y/o
          exista peligro de incendio o explosión del vehículo o riesgo de que la
          persona sea atropellada en la calzada. Permanecer junto a la persona
          herida. Si está consciente, intentar tranquilizarla.
        </li>
        <li>
          Pedí, y entregá, los datos de la Licencia de Conducir y del Seguro
          Obligatorio: nombre, apellido, DNI del conductor, domicilio real,
          e-mail, patente del vehículo, nombre de la compañía aseguradora. (Art.
          107 inc. 2 de la Ley de Transito de Mendoza 9024)
        </li>
        <li>Tomá fotos de todo lo ocurrido.</li>
        <li>Buscá algunos testigos y pediles sus datos personales.</li>
        <li>
          Denunciá el siniestro dentro de las 48 hs ante la autoridad competente
          (Policía de Mendoza o Juzgado Administrativo Municipal de Tránsito) en
          caso de que no hayan intervenido. (Art. 107 inc. c de la Ley de
          Transito de Mendoza 9024).
        </li>
        <li>
          Denunciá el siniestro a tu compañía de seguros dentro de las 72 horas
          de ocurrido. (Art. 46 y 115 Ley de Seguros 17.418).
        </li>
        <li>
          Realizá el reclamo a la compañía de seguros correspondiente con un
          abogado de confianza o con nuestra plataforma clic.social de manera
          rápida, segura y gratuita.
        </li>
      </ul>
    </div>
  </section>
</template>
